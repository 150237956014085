import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamation } from 'react-icons/fa';
import styles from './PossessionNote.module.css';

export const PossessionNote: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.possessionNoteContainer}>
      <FaExclamation color={'#15cf74'} size={50} />
      <p className={styles.text}>{t('possessionNote.text')}</p>
    </div>
  );
};
